/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
/* eslint react/prop-types: 0 */
import { ErrorMessage, Field } from "formik";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

function FormField({ label, name, ...rest }) {
  return (
    <Box mb={1.5}>
      <Field {...rest} name={name} as={TextField} variant="standard" label={label} fullWidth />
      <Box mt={0.75}>
        <Typography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </Typography>
      </Box>
    </Box>
  );
}

export default FormField;
