/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/
/* eslint-disable */ 

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// individal icons
import PowerInputIcon from '@mui/icons-material/PowerInput';
import GrassIcon from '@mui/icons-material/Grass';
import NatureIcon from '@mui/icons-material/Nature';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Stack from "@mui/material/Stack";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function RegenAgResources({ sectionId }) {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} id={sectionId}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Regenerative Agriculture
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={2}>
              Carbon credit is free money for ranchers and farmers who practice regenerative and/or
              conservational agriculture.
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body4"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Find out more
              <Icon sx={{ fontWeight: "bold" }}><ArrowForwardIcon/></Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon={<PowerInputIcon/>}
                content={
                  <>
                    <MKTypography variant="body4" color="primary">Cropland Management
                    <br />
                    Cover crops, No-till, Crop Rotation</MKTypography>
                    <MKTypography
                      component="a"
                      href="#"
                      variant="body4"
                      color="info"
                      fontWeight="regular"
                      sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                          fontSize: "1.125rem",
                          transform: "translateX(3px)",
                          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: "translateX(6px)",
                        },
                      }}
                    >
                      Find out more
                      <Icon sx={{ fontWeight: "bold" }}><ArrowForwardIcon/></Icon>
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon={<GrassIcon/>}
                content={
                  <>
                    <MKTypography variant="body4" color="primary">Grazing Lands
                    <br />
                    Prescribed Grazing, Silvopasture, Range Planting</MKTypography>
                    <MKTypography
                      component="a"
                      href="#"
                      variant="body4"
                      color="primary"
                      fontWeight="regular"
                      sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                          fontSize: "1.125rem",
                          transform: "translateX(3px)",
                          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: "translateX(6px)",
                        },
                      }}
                    >
                      Find out more
                      <Icon sx={{ fontWeight: "bold" }}><ArrowForwardIcon/></Icon>
                    </MKTypography>
                  </>
                }
              />
              <AboutUsOption
                icon={<NatureIcon/>}
                content={
                  <>
                    <MKTypography variant="body4" color="primary">Woody Plantings
                    <br />
                    Riparian Forest Buffer, Windbreak/Shelterbelt</MKTypography>
                    <MKTypography
                      component="a"
                      href="#"
                      variant="body4"
                      color="info"
                      fontWeight="regular"
                      sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                          fontSize: "1.125rem",
                          transform: "translateX(3px)",
                          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: "translateX(6px)",
                        },
                      }}
                    >
                      Find out more
                      <Icon sx={{ fontWeight: "bold" }}><ArrowForwardIcon/></Icon>
                    </MKTypography>
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
RegenAgResources.defaultProps = {
  sectionId: "regen-ag",
};

// Typechecking props for the SimpleInfoCard
RegenAgResources.propTypes = {
  sectionId: PropTypes.string,
};
export default RegenAgResources;
