/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================
* 
* Copyright 2022 CarbonAGX
 ========================================================
* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// prop-types is a library for type checking of props
import PropTypes from "prop-types";
// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function OurStory({ sectionId }) {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 6 }} mt={6} id={sectionId}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={10} mx="auto">
          <MKTypography variant="h3" color="Our Story">
            Our Story
          </MKTypography>
          <MKTypography variant="body3">
            CarbonAGX was founded on the premise that technology can be harnessed for good – for the
            rancher, the farmer, and the long-term sustainability of the land. Our vision is to help
            ranchers and farmers better understand how they can easily, and fairly, get rewarded for
            their regenerative agricultural activities - without taking time away from the business
            of ranching and farming. <br />
            <br />
            The carbon credit marketplace is disjointed and complicated. There’s currently a high
            demand for carbon credits from Fortune 500 companies seeking to offset their carbon
            emissions. On the supply side, there’s currently a low supply of high-quality,
            verifiable agriculture carbon credits. Given that demand is outpacing supply, one would
            expect that an agriculture carbon credit would command a high price. However, due to the
            complexity of the MRV (measurement, reporting, and verification) process in regenerative
            agriculture, the price for an agriculture carbon credit was significantly lower in
            comparison to other sectors.
            <br />
            <br />
            CarbonAGX’s technology helps the agricultural carbon credit market to scale by
            digitizing and automating the carbon credit measurement, reporting, and verification
            process —one that is currently manual, biased, and expensive for ranchers and farmers.{" "}
            <br />
            <br />
            CarbonAGX’s technology brings together data, standards, rules, and workflows from direct
            participants of agriculture production, such as ranchers, farmers, agriculture
            suppliers, distributors and buyers, the scientific community, the regulatory agencies,
            and the carbon credit registries.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

OurStory.propTypes = {
  sectionId: PropTypes.node.isRequired,
};
export default OurStory;
