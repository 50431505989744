/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* Copyright 2022 CarbonAGX

 =========================================================

*/

// import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// CarbonAGX Website themes
import theme from "assets/theme";
import Home from "layouts/pages/home";
import LoginPage from "layouts/authentication/sign-in/illustration";
import AboutUs from "pages/AboutUs";
import ResourcesPage from "pages/Resources";
import PartnerPage from "pages/Partners";
import ContactUs from "pages/Support/ContactUs";
import Calculator from "pages/Calculator";

// CarbonAGX Website routes
import routes from "routes";
import { useEffect } from "react";
import ReactGA from "react-ga4";

ReactGA.initialize("G-1KZE5FTVPE", {
  debug: true,
  //standardImplementation: true,
  /*gaOptions: {
    siteSpeedSampleRate: 100
  }*/
});

export default function App() {
  // const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  /* useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]); */

  useEffect(() => {
    console.log("Test: " + window.location.pathname + window.location.search + window.location.hash);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Custom Title" });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/partners" element={<PartnerPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </ThemeProvider>
  );
}
