/*
=========================================================
* CarbonAGX Website
=========================================================
* 
* Copyright 2022 CarbonAGX
=========================================================
* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import OurStory from "pages/AboutUs/sections/OurStory";
import Team from "pages/AboutUs/sections/Team";
import AdvisoryBoard from "pages/AboutUs/sections/AdvisoryBoard";

// CarbonAGX Website examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/golden_yield.jpg";
import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    document.title = "About";  
  }, []);
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          backgroundColor: "black",
          opacity: "1",
          filter: "grayscale(35%)",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} pl={4}>
              <MKTypography
                align="Left"
                variant="h1"
                color="title"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <div>
                  We Deliver <b>Carbon Credit Income</b>
                </div>

                <div>to Ranchers and Farmers</div>
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 0, sm: 2, lg: 3 },
          mt: { xs: -20, md: -8 },
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <OurStory sectionId="our-story" />
        <Team sectionId="team" />
        <AdvisoryBoard sectionId="advisory-council" />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
