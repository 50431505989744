/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// CarbonAGX Website examples
import VirticalTeamCard from "examples/Cards/TeamCards/VirticalTeamCard";

// Images
import lisa from "assets/images/portrait_lisa.jpg";
import kevin from "assets/images/portrait_kevin.jpg";
import hannah from "assets/images/portrait_hannah.jpg";
import ganesh from "assets/images/portrait_ganesh.jpg";
import peter from "assets/images/portrait_peter.jpg";

function Team({ sectionId }) {
  return (
    <MKBox
      component="section"
      bgColor="primary"
      position="relative"
      py={12}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id={sectionId}
    >
      <Container>
        <Grid container spacing={0} item xs={12} lg={10} mx="auto">
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Team
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, sm: 3 }} item xs={12} lg={10} mx="auto">
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <VirticalTeamCard
                image={lisa}
                name="Lisa Hagerman, PhD"
                email={{ label: "lisa@carbonagx.com", href: "mailto: lisa@carbonagx.com" }}
                position={{ color: "dark", label: "Co-Founder" }}
                description="Lisa has 20 years of experience in the field of impact investing and sustainable finance. She is a leader in cross-sector collaboration and in the creation of public-private partnerships to accelerate economic development. Prior to joining CarbonAGX, she spent over ten years as the Director of Programs at DBL Partners, an impact venture capital firm. In this role, she led the firm’s impact measurement and management practice across the areas of public policy, environmental stewardship, and workforce development. Previously, Lisa was the Director of More for Mission, a research and advocacy initiative promoting mission investing, at the Harvard Kennedy School in which she built a network of over 90 foundations representing over $38 billion in total assets. In 2012, More for Mission merged with PRI Makers to form Mission Investors Exchange. Lisa completed her doctorate in economic geography at Oxford University on Public Pension Fund Investment in Urban Revitalization, a project supported by the Ford and Rockefeller Foundations. She was previously a Vice President at Economic Innovation International and has ten years of banking experience at Wells Fargo and Citibank. Lisa also serves as the Executive Director of the Daniel P. Hagerman Foundation that supports environmental stewardship. Lisa received her B.A. from Bucknell University, her M.A. in political science from the University of North Carolina at Chapel Hill, and a Ph.D. in economic geography from Oxford University."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <VirticalTeamCard
                image={kevin}
                name="Kevin Sun"
                email={{ label: "kevin@carbonagx.com", href: "mailto: kevin@carbonagx.com" }}
                position={{ color: "dark", label: "Co-Founder" }}
                description="Kevin has 30 years of technology experience in the field of cash management and  climate finance. He has a proven track record of realizing innovative and disruptive product visions using cutting-edge technologies. Prior to joining CarbonAGX, he was a Managing Director at BlackRock. Kevin was the CTO and Technology Founder for Cachematrix, a Liquidity Trading Platform with $1 trillion under management. Cachematrix was acquired by BlackRock in 2017. Kevin was the technology leader and software architect at USWest, E*Trade, and the United States Department of Labor. He developed mission-critical systems that are key to the rollout of broadband internet and online trading in the 90s and was instrumental in the US government web initiatives in early 2000. Kevin received his M.S. in Computer Science from the University of Texas and his B.S. in Computer Science from Zhejiang University, China."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <VirticalTeamCard
                image={peter}
                name="Peter Sun"
                email={{ label: "peter@carbonagx.com", href: "mailto: peter@carbonagx.com" }}
                position={{ color: "dark", label: "Software Engineer" }}
                description="Peter is a recent graduate from Southern Methodist University, majoring in computer science. A passionate full-stack software engineer, technology and exploring all that's possible to build with it has been a life-long interest of his. Peter was a software engineering intern at Spacee, where he used his skills to help improve web applications end-to-end to interface with devices for use for clients in the retail industry, and has brought this diverse skillset over to CarbonAGX."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <VirticalTeamCard
                image={ganesh}
                name="Ganesh Baskar"
                email={{ label: "ganesh@carbonagx.com", href: "mailto: ganesh@carbonagx.com" }}
                position={{ color: "dark", label: "Summer Associate" }}
                description="Ganesh Baskar is a Summer Associate at CarbonAGX in 2024. Ganesh is currently an MBA candidate at SMU Cox School of Business specializing in financial strategy and management consulting. Previously, Ganesh served in various business roles in financial operations at BNY Mellon and supply chain industries, at Ryder Supply Chain Solutions and the University of Texas at Dallas. He enjoys integrating the financial side of a business to maintain operational efficiency. Ganesh received his B.S. in Supply Chain Management & Finance from the University of Texas at Dallas."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <VirticalTeamCard
                image={hannah}
                name="Hannah Schilling"
                email={{ label: "hannah@carbonagx.com", href: "mailto: hannah@carbonagx.com" }}
                position={{ color: "dark", label: "Summer Intern" }}
                description="Hannah Schilling is a Summer Intern at CarbonAGX in 2024. Hannah is currently a senior in the College of Arts and Sciences at Bucknell University studying Sociology and Philosophy and an Arts Merit Scholar with a minor in Dance. She works as a part-time Student Development Monitor/Officer at Bucknell, dancing as a member of the Bison Girls Dance Team and Bucknell Dance Company and is a member of the Phi Sigma Tau International Honors Society in Philosophy. In addition to her interests in conservation agriculture, her long-term career goals are in the fields of behavioral health or law."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Team.propTypes = {
  sectionId: PropTypes.node.isRequired,
};

export default Team;
