/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// CarbonAGX Website pages
import SignInIllustration from "pages/Authentication/SignIn/Illustration";

export default function SignInIllustrationPage() {
  return <SignInIllustration />;
}
