/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
