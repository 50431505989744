/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================
* 
* Copyright 2022 CarbonAGX
=========================================================
* 
*/

/**
 * The base breakpoints for the CarbonAGX Website.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire CarbonAGX Website using thie file.
 */

export default {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
