/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// CarbonAGX Website pages
import Home from "pages/Home";

export default function HomePage() {
  return <Home />;
}
