/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
 
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, copyright } = content;

  return (
    <MKBox component="footer">
      <Container align="center">
        <Grid container>
          <Grid item xs={12} md={4} sx={{ my: -1 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxHeight="50px" mb={2} />
              </Link>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4} mb={{ xs: 2, sm: 0 }}>
            <MKTypography variant="h4" color="text">
              {copyright}
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} mb={{ xs: 3, sm: 0 }}>
            <MKButton color="third" href="/contact-us">
              Contact Us
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
