/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// prop-types is a library for type checking of props
import PropTypes from "prop-types";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import advisor1 from "assets/images/advisor_1_kate.png";
import advisor2 from "assets/images/advisor_2_stuart.jpg";
import advisor3 from "assets/images/advisor_3_ashby.jpg";
import advisor4 from "assets/images/advisor_4_betsy.jpg";

function AdvisoryBoard({ sectionId }) {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id={sectionId}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Advisory Council
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={advisor4}
                name="Betsy Indreland"
                position={{ color: "info", label: "" }}
                description="Betsy Indreland is the co-owner and rancher of Indreland Ranch located in Big Timber, Montana.  Betsy, and her husband, Roger Indreland, have been holistically managing and operating Indreland Ranch for over forty years.  Betsy is well known in the ranching community and advocates for regenerative grazing for the benefit of healthy nutrition, the environment, livestock and wildlife. The Indreland’s holistic ranching is accomplished through practices that foster resilience to economic and environmental factors and by providing opportunities and profit to all involved. Indreland Angus is a registered Angus business rooted in the philosophy that cows must consistently excel at converting the resource of basic grass to beef. Betsy is a graduate of Montana State University, Bozeman."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={advisor3}
                name="Ashby Monk"
                position={{ color: "info", label: "" }}
                description={<>Ashby Monk is the Executive Director of the Stanford Research Initiative on Long-Term Investing. Outside of academia, he has co-founded several companies that apply advanced analytics to drive better capital allocation and investment decision-making, including RCI Navigator (acquired by <a href="https://addepar.com/addepar-navigator">Addepar</a>), <a href="https://www.futureprooftech.io/">FutureProof</a>, <a href="https://www.netpurpose.com/">NetPurpose</a>, <a href="https://deceptionandtruthanalysis.com/">D.A.T.A.</a>, <a href="https://growthsphere.ai/">GrowthsphereAI</a>, <a href="https://sheltonai.com/">SheltonAI</a>, Long Game Savings (acquired by <a href="https://www.wsj.com/articles/truist-financial-acquires-gamified-fintech-startup-long-game-11652219591">Truist</a>), and <a href="https://kdx.vc/">KDX</a>. He is the co-author of The Technologized Investor and is a member of the CFA Institute’s Future of Finance Advisory Council. He was named by CIO Magazine as one of the most influential academics in the institutional investing world. He received his Doctorate in Economic Geography at Oxford University and holds a Master’s in International Economics from the Universite de Paris I - Pantheon Sorbonne and a Bachelor’s in Economics from Princeton University.</>}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={advisor1}
                name="Kate Lauer"
                position={{ color: "info", label: "" }}
                description="Kate Lauer is Legal Counsel, Strategic Priorities at Stripe, a technology company that builds economic infrastructure for businesses to transact on the internet. For over 30 years, Kate has worked as a lawyer and policy advisor. For almost 20 years, she advised financial regulators and global standard setting bodies on developing risk-based approaches to regulation and supervision of nonbank institutions in order to advance financial inclusion. She is also a member of the board of directors of the International Accountability Project.  Kate received her JD from New York University and a BA in Economics from Dartmouth College."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={advisor2}
                name="Stuart Grandy"
                position={{ color: "info", label: "" }}
                description="Stuart Grandy is a Professor of Soil Fertility and Biogeochemistry and Co-Director of the UNH Center of Soil Biogeochemistry and Microbial Ecology (Soil BioME). He leads interdisciplinary teams that examine how agroecosystem management influences soil health as well as the microbial processes driving soil organic matter formation and nutrient cycling. He has published more than 100 academic papers in top academic journals detailing the role of soil organisms in regulating soil carbon cycling, trace gas emissions, and productivity. His research applies novel microbial-explicit models and fundamental understanding of soil biogeochemical processes to improve agroecosystem processes and management in collaboration with academic, industry and producer stakeholders. "
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
AdvisoryBoard.propTypes = {
  sectionId: PropTypes.node.isRequired,
};
export default AdvisoryBoard;
