/* eslint react/prop-types: 0 */
/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
// prop-types is a library for typechecking of props
import { useState } from "react";
import axios from "axios";
// formik components
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import validations from "../../schemas/validations";
import form from "../../schemas/form";
import initialValues from "../../schemas/initialValues";

import Location from "../Location";
import { extractPracticeMap } from "../../calculator";
import ReactGA from "react-ga4"

function SearchForm({ setResultsData, setPracticeMap }) {
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  /* const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    }); */

  const submitForm = async (values, actions) => {
    setLoadingSpinner(true);
    // this is where you should call react-ga4 and pass in state and county
    ReactGA.event({
      category: "Calculator",
      action: "calculator_" + values.county + "_" + values.state,
      label: values.county + ", " + values.state, // optional
      value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
    axios
      .get("https://1n390gcfgc.execute-api.us-east-1.amazonaws.com/Stage/ghg-data", {
        params: { state: values.state, county: values.county },
      })
      .then((response) => {
        let index = 0;
        const resultsData = response.data.map((x) => {
          const record = x;
          // add id with index
          record.id = index;
          index += 1;
          // determine if it is multiple implementation
          if (x.planner_implementation.includes(" + ")) {
            record.isMultipleImplementation = true;
            const liArray = x.planner_implementation.split(" + ");

            record.implementation = liArray;
          } else {
            record.isMultipleImplementation = false;
            record.implementation = [record.planner_implementation];
          }
          return record;
        });
        setResultsData(resultsData);

        setPracticeMap(extractPracticeMap(resultsData));

        setLoadingSpinner(false);

        //resetUserData();
      })
      .catch(() => {
        setLoadingSpinner(false);
        // eslint-disable-next-line no-console
      });

    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    setResultsData([]);
  };
  const [landType, setLandType] = useState("Grazing Lands");

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form id={formId} autoComplete="off">
            <Location
              formData={{
                values,
                touched,
                formField,
                errors,
                isSubmitting,
                landType,
                setLandType,
              }}
            />
          </Form>
        )}
      </Formik>
      {loadingSpinner && (
        <CircularProgress
          size={24}
          sx={{
            color: "text",
            position: "absolute",
            top: "50%",
            left: "20%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
}

export default SearchForm;
