/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
/* eslint react/prop-types: 0 */
import {
  Button,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  MobileStepper,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Container,
  Link,
  CardActions,
  Dialog,
  DialogTitle,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import typography from "assets/theme/base/typography";

const IncomeCard = ({ topData, showList }) => {
  let [bannerMargin, setBannerMargin] = useState(["1em", "1em"]);

  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disclosureOpen, setDisclosureOpen] = useState(false);
  /*let [contactPadding, setContactPadding] = useState("10em");
  useEffect(() => {
    function mobileView() {
      if (window.innerWidth > 1006) {
        setContactPadding("10em");
      } else if (window.innerWidth < 1006 && window.innerWidth > 768) {
        setContactPadding("1em");
      }
      else {
        setContactPadding("0em");
      }
    }
    window.addEventListener("resize", mobileView);
    // set state with initial value
    mobileView();
    // clean up window event listener
    return () => window.removeEventListener("resize", mobileView);
  }, [window.innerWidth]);*/

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleDiscloseOpen = () => {
    setDisclosureOpen(true);
  }
  const handleDiscloseClose = () => {
    setDisclosureOpen(false);
  }
  return (
    topData &&
    topData.length > 0 && (
      <Box>
        <Card
          sx={{
            sm: { maxWidth: 445 },
            borderRadius: 0,
            border: "none",
            boxShadow: "none",
          }}
        >
          <CardHeader
            title={topData[activeStep].implementationDesc + " **"}
            titleTypographyProps={{ variant: "button", color: "info" }}
            avatar={
              <Avatar sx={{ bgcolor: "error.focus" }} aria-label="ranking">
                {activeStep + 1}
              </Avatar>
            }
            action={
              <Box>
                <IconButton aria-label="actions" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => showList()}>compare practices</MenuItem>
                </Menu>
              </Box>
            }
          />
          <CardContent>
            <Box>
              <Typography variant="h1Sans" color="success.main">
                {topData[activeStep].outcomeValue} Annual Income
              </Typography>
            </Box>

            <Box mt={2} sx={{ display: { /*xs: "none",*/ sm: "block" } }}>
              <Grid container>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box justifyContent="center">
                        <Typography variant="caption">greenhouse gas reduction</Typography>
                      </Box>
                      <Box alignItems="center">
                        <Typography variant="caption" align="center">
                          <b>{topData[activeStep].ghg}</b>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box justifyContent="center">
                        <Typography variant="caption">soil organic carbon increase</Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">
                          <b>{topData[activeStep].soc}</b>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <MobileStepper
            sx={{
              bgcolor: "#ffffff00",
            }}
            variant="dots"
            steps={topData.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === topData.length - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
          <CardContent>
          <CardActions sx={{justifyContent: "center", alignItems: "center", mt: "-1.5em", mb: "-0.5em"}}>
            <Button href="/" sx={{ fontSize: 8 }}>Learn More</Button>
            <Button href="/contact-us" sx={{ fontSize: 8 }}>Contact Us</Button>
            <Button onClick={handleDiscloseOpen}  sx={{fontSize: 8}}>**data source disclosure</Button>
          </CardActions>
          </CardContent>
          <Dialog onClose={handleDiscloseClose} open={disclosureOpen}>
            <Box sx={{padding: "0.5em"}}>
            <Typography variant="sourceDisclosure">
            Data source contributors<br/>
            Amy Swan, Mark Easter, Adam Chambers, Kevin Brown, Stephen A. Williams, Jeff Creque, John Wick, and Keith Paustian. COMET-Planner: Carbon and Greenhouse Gas Evaluation for NRCS Conservation Practice Planning. Viewed and downloaded on March 1st, 2022 at comet-planner.com.
            </Typography>
            </Box>
          </Dialog>
        </Card>
      </Box>
    )
  );
};

export default IncomeCard;
