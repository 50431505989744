/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/
/* eslint-disable */ 

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// CarbonAGX Website examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
// icons
import CircleIcon from '@mui/icons-material/Circle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';

function CarbonCreditResources({ sectionId }) {
  return (
    <MKBox component="section" py={24} bgColor="primary" id={sectionId}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="left"
          pb={6}
        >
          <MKTypography variant="h3" mb={1}>
            <i>Carbon Credits & the Voluntary Carbon Markets</i>
          </MKTypography>
          <MKTypography variant="body3" color="dark">
            Quality of carbon credits is determined by the following factors. High quality carbon
            credits accurately quantifies these factors.
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon={<CircleIcon/>}
              title="Permanence"
              description="Predicting and tracking changes of the practices."
              direction="center"
              descriptionColor="dark"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon={<ChangeHistoryIcon/>}
              title="Additionality"
              description="Identify climate benefit of the practices."
              direction="center"
              descriptionColor="dark"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              descriptionColor="dark"
              icon={<LeakRemoveIcon/>}
              title="Leakage"
              description="Detect negative climate impact of old practices."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
CarbonCreditResources.defaultProps = {
  sectionId: "carbon-credits",
};

// Typechecking props for the SimpleInfoCard
CarbonCreditResources.propTypes = {
  sectionId: PropTypes.string,
};
export default CarbonCreditResources;
