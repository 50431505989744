/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
import checkout from "./form";

const {
  formField: { state, county },
} = checkout;

const initialValues = {
  [state.name]: "",
  [county.name]: "",
};

export default initialValues;
