/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/

// prop-type is a library for typechecking of props
/* eslint react/prop-types: 0 */

import { Grid, Autocomplete, Button, Typography } from "@mui/material";
import { useState } from "react";
// NewUser page components

import stateCountyMap from "../../schemas/state_county.json";
import FormField from "../FormField";

function Location({ formData }) {
  const { formField, values, errors, touched, isSubmitting } = formData;
  const { county, state } = formField;
  const [countyByState, setCountyByState] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sm={2}>
        <Autocomplete
          options={Object.keys(stateCountyMap)}
          disableClearable
          onChange={(event, selectedValue) => {
            values.state = selectedValue;
            setCountyByState(stateCountyMap[selectedValue]);
            setSelectedCounty(null);
            values.county = "";
          }}
          renderInput={(params) => (
            <FormField
              {...params}
              variant="standard"
              type={state.type}
              label={state.label}
              name={state.name}
              placeholder={state.placeholder}
              error={errors.state && touched.state}
              success={values.state.length > 0 && !errors.state}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          options={countyByState}
          disableClearable
          value={selectedCounty}
          onChange={(event, selectedValue) => {
            values.county = selectedValue;
            setSelectedCounty(selectedValue);
          }}
          renderInput={(params) => (
            <FormField
              {...params}
              variant="standard"
              type={county.type}
              label={county.label}
              name={county.name}
              placeholder={county.placeholder}
              error={errors.county && touched.county}
              success={values.county.length > 0 && !errors.county}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button disabled={isSubmitting} type="submit" variant="gradient" color="dark">
          <Typography variant="standard" fontWeight="small" color="light">
            go
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default Location;
