/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import {
  Button,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Autocomplete,
  Box,
  Typography,
} from "@mui/material";
import Slider from "@mui/material/Slider";

import SearchForm from "./components/SearchForm";
import dataTableData from "./components/ResultTable/data/dataTableData";
import TopOutcomesCard from "./components/TopOutcomesCard";

import { ACRE_FACTOR, getTopOutcomes, updateDataPoints } from "./calculator";

import IncomeCard from "./components/IncomeCard";
import MKTypography from "components/MKTypography";
import ReactGA from "react-ga4"


function CalcWidget() {
  const [selectedCategories, setSelectedCategories] = useState(["Cropland Management"]);
  const [resultsData, setResultsData] = useState(dataTableData.rows);
  const [area, setArea] = useState(1);
  const [stepperArea, setStepperArea] = useState(1);
  const [sliderArea, setSliderArea] = useState(1);
  const [price, setPrice] = useState(25);
  const [landSize, setLandSize] = useState(2);
  const [changeLocation, setChangeLocation] = useState(true);
  const [topData, setTopData] = useState(
    getTopOutcomes(dataTableData.rows, price, area, selectedCategories)
  );
  const widget = { top1: 0, top5: 1, table: 2 };
  const [showWidget, setShowWidget] = useState(widget.top1);
  const [enableFilterCard, setEnableFilterCard] = useState(false);
  const [practiceMap, setPracticeMap] = useState(new Map());
  const [practiceFilters, setPracticeFilters] = useState([]);
  const [practiceFilterValues, setPracticeFilterValues] = useState([]);

  useEffect(() => {
    if (landSize === 1) {
      setArea(stepperArea);
      console.log("Area: " + area);
      updateDisplayData();
    }
  }, [area])

  useEffect(() => {
    if (landSize === 1) {
      setArea(stepperArea);
      console.log("Area: " + area);
      updateDisplayData();
    } else {
      if (sliderArea === 0) {
        setArea(1);
      } else {
        setArea(sliderArea);
      }
    }
  }, [stepperArea, sliderArea])
  useEffect(() => {
    if (sliderArea === 0) {
      setArea(1);
    } else {
      setArea(sliderArea);
    }
  }, [sliderArea])

  const updateResultsData = (data, filters, newCategories, isApiCall = true, factor = ACRE_FACTOR) => {
    console.log(`area: ${area}`);
    const enrichedData = updateDataPoints(data, price, area, factor);
    setResultsData(enrichedData);
    setTopData(
      getTopOutcomes(enrichedData, newCategories ?? selectedCategories, filters ?? practiceFilters)
    );
    if (isApiCall) {
      // if results data is returned from API, set change location to false
      setChangeLocation(false);
    }

  };

  const updateDisplayData = (filters, newCategories) => {
    let factor = ACRE_FACTOR;
    if (landSize === 1) {
      factor = 1;
    }
    if (resultsData && resultsData.length > 0) {
      updateResultsData(resultsData, filters, newCategories, false, factor);
    }
  };

  // #region area slider functions
  const handleSliderChange = (event, newValue) => {
    setSliderArea(newValue);
    updateDisplayData();
  };
  // #endregion

  // #region area stepper founctions
  const handleStepperChange = (event) => {
    console.log(`stepper=${event.target.value}`);
    setStepperArea(event.target.value);
    ReactGA.event({
      category: "Calculator",
      action: "calculator_stepper_area",
      label: "", // optional
      value: event.target.value * 1, // optional, must be a number ALSO i have no idea why i need to multiply by 1 to make it work
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
    //updateDisplayData();
  };

  // #region carbon credit price slider functions

  const priceSlider = (caption = true, priceFont = "h4Sans") =>
    resultsData &&
    resultsData.length > 0 && (
      <>
        {caption && <Typography variant="button">Carbon Credit Price</Typography>}
        <br />
        <Typography variant={priceFont}>${price} /ton/acre</Typography>
        <Slider
          defaultValue={price}
          step={1}
          onChange={(event, newValue) => {
            setPrice(newValue);
            updateDisplayData();
          }}
        />
      </>
    );

  const areaChooser = (choice) => {
    if (choice == 1) {
      return (
        <>
          <br />
          <Box sx={{ py: 2 }}>
            <TextField type="number" value={stepperArea} label="acre" onChange={handleStepperChange} />
          </Box>
        </>
      )
    } else {
      return (
        <>
          <Slider value={sliderArea} onChange={handleSliderChange}
            onMouseUp={
              () => {
                ReactGA.event({
                  category: "Calculator",
                  action: "calculator_slider_area",
                  label: "", // optional
                  value: sliderArea * ACRE_FACTOR, // optional, must be a number
                  //nonInteraction: true, // optional, true/false
                  //transport: "xhr", // optional, beacon/xhr/image
                });
              }
            }
          />
        </>
      )
    }
  }
  const landBigOrSmall = () => {
    return (
      <ToggleButtonGroup
        value={landSize}
        exclusive
        onChange={(event, values) => {
          if (values && values !== landSize) {
            setLandSize(values);
          }
        }}
      >
        <ToggleButton value={1}>Small</ToggleButton>
        <ToggleButton value={2}>Large</ToggleButton>
      </ToggleButtonGroup>
    )
  }

  // #endregion
  const areaSlider = (caption = true, priceFont = "h4Sans") => (
    <>
      {caption && <Typography variant="button">My land size is  </Typography>}
      {landBigOrSmall()}
      <br />
      {(landSize === 2) && (<Typography variant={priceFont}>{Math.round((sliderArea === 0 ? 1 : sliderArea) * ACRE_FACTOR)} acre</Typography>)}
      {/*<Slider value={area} onChange={handleSliderChange} />*/}
      {areaChooser(landSize)}
    </>
  );

  const filterCard = enableFilterCard && practiceMap && practiceMap.size > 0 && (
    <Box>
      <Autocomplete
        multiple
        options={[...practiceMap.get(selectedCategories[0]).keys()]}
        onChange={(event, selectedValues) => {
          let filterValues = [];
          if (selectedValues || [...selectedValues].length > 0) {
            filterValues = selectedValues.map((x) => practiceMap.get(selectedCategories[0]).get(x));
          }
          setPracticeFilterValues(selectedValues);
          setPracticeFilters(filterValues);
          updateDisplayData(filterValues);
        }}
        defaultValue={[]}
        value={practiceFilterValues}
        clearOnBlur
        renderInput={(params) => (
          <TextField {...params} placeholder="Practice Areas" variant="standard" />
        )}
      />
    </Box>
  );

  const landType = (
    <ToggleButtonGroup
      value={selectedCategories[0]}
      exclusive
      onChange={(event, values) => {
        if (values && values !== selectedCategories) {
          setSelectedCategories([values]);

          setPracticeFilters([]);
          setPracticeFilterValues([]);
          updateDisplayData([], [values]);
        }
      }}
    >
      <ToggleButton value="Cropland Management">Farm</ToggleButton>
      <ToggleButton value="Grazing Lands">Ranch</ToggleButton>
    </ToggleButtonGroup>
  );

  const top5Card = () => {
    const cate = selectedCategories[0];

    return (
      topData[selectedCategories[0]].length > 0 && (
        <TopOutcomesCard
          practiceCategory={cate}
          outcomeData={topData[selectedCategories[0]]}
          showSh={false}
          showCommand={setShowWidget}
          lessWidget={widget.top1}
          tableWidget={widget.table}
        />
      )
    );
  };

  const displayTop = () => {
    let retVal;
    switch (showWidget) {
      case widget.top1:
        retVal = (
          <IncomeCard
            topData={topData[selectedCategories[0]]}
            showList={() => setShowWidget(widget.top5)}
          />
        );
        break;
      case widget.top5:
        retVal = top5Card();
        break;
      default:
        break;
    }

    return retVal;
  };


  return (
    <Box>
      {(changeLocation || !resultsData || resultsData.length === 0) && (
        <Box mb={2}>
          <MKTypography variant="h4" sx={{ pb: "1em" }}>Find Top Carbon Credit Practices</MKTypography>
        </Box>
      )}
      <Box xs={12} sm={6} alignItems="center">
        <Typography variant="button">
          <Box sx={{ pb: "0.5em" }}>
            My {landType}
            {"  "}is located in{" "}
          </Box>
          <b>
            {resultsData &&
              resultsData.length > 0 &&
              resultsData[0].county.concat(", ").concat(resultsData[0].state).concat(". ")}
          </b>
        </Typography>
        {!changeLocation && resultsData && resultsData.length > 0 && (
          <Button
            size="small"
            onClick={() => {
              setChangeLocation(true);
            }}
          >
            change location
          </Button>
        )}
        {(changeLocation || !resultsData || resultsData.length === 0) && (
          <SearchForm setResultsData={updateResultsData} setPracticeMap={setPracticeMap}
            resetUserData={() => {
              // going to be used to reset the slider every time a new location is chosen
              //setSliderArea(1);
              //updateDisplayData();
            }} />
        )}
      </Box>
      {resultsData &&
        resultsData.length > 0 &&
        ((showWidget !== widget.table && (
          <Box mt={{ xs: 0, sm: 3 }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={{ sm: 2 }}>{filterCard}</Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={3} alignItems="left center">
                <Grid item xs={12} sm={4} sx={{ display: { xs: "none", sm: "block" } }}>
                  <Box>{areaSlider()}</Box>
                  <Box>{priceSlider()}</Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {displayTop()}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Box mt={1}>{filterCard}</Box>
              <Box>{areaSlider()}</Box>
              <Box>{priceSlider()}</Box>
            </Box>
          </Box>
        )) || <Box mt={{ xs: 0, sm: 3 }}>{displayTop()}</Box>)}
    </Box>
  );
}

export default CalcWidget;
