/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { state, county },
} = checkout;

const validations = [
  Yup.object().shape({
    [state.name]: Yup.string().required(state.errorMsg),
    [county.name]: Yup.string().required(county.errorMsg),
  }),
];

export default validations;
