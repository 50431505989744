/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// CarbonAGX Website base styles
import borders from "assets/theme/base/borders";

// CarbonAGX Website helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      display: "block",
      padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
      borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    },
  },
};
