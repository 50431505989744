/*
=========================================================
* CarbonAGX Website
=========================================================
* 
* Copyright 2022 CarbonAGX
=========================================================
* 
*/

// for static content
import * as ReactDOMServer from 'react-dom/server';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// CarbonAGX Website examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/regen_ag_2.jpg";
import bioDiversity from "assets/images/bio-diversity.jpg";

// import breakpoints
import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useState } from "react";
function Home() {
  useEffect(() => {
    document.title = "CarbonAGX";  
  }, []);
  /*let [bannerMargin, setBannerMargin] = useState(["1em", "1em"]); // [mt, mb]
  useEffect(() => {
    function mobileView(){

      if (window.innerWidth < breakpoints.values.lg && window.innerWidth > 768) {
        setBannerMargin(["2em", "2em"]);
      }else if(window.innerWidth < 768){
        setBannerMargin(["3.5em", "5.5em"]);
      }
      else{
        setBannerMargin(["1em", "1em"]);
      }
    }
    window.addEventListener("resize", mobileView);
    // set state with initial value
    mobileView();
    // clean up window event listener
    return () => window.removeEventListener("resize", mobileView);
  }, ());*/

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="70vh"
        height="auto"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={6} pl={3}>
              <MKTypography
                align="left"
                variant="h1"
                color="title"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  mt: {xs: "3.5em", sm: "3.5em", md: "2em", lg: "0.5em"},
                  mb: {xs: "5.5em", sm: "5.5em", md: "2em", lg: "0.5em"}
                })}
              >
                <div>Carbon Credits</div>
                <div>
                  for <b>Regenerative Agriculture</b>
                </div>
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          pt: 2,
          px: { xs: 3, sm: 0 },
          mt: { xs: -20, md: -8 },
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={1} mt={{ sm: 6, xs: 3 }} mb={{ sm: 6, xs: 3 }}>
          <Container>
            <Grid container alignItems="left, top">
              <Grid container item alignItems="top" mx={{ md: 2, sm: 2 }} spacing={2}>
                <Grid item xs={12} lg={6} align="top">
                  <MKTypography variant="h3" color="text">
                    <i>
                      CarbonAGX helps ranchers and farmers keep their carbon credit income potential.{" "}
                    </i>
                  </MKTypography>
                  <MKBox
                    sx={{
                      maxWidth: { xs: 450, lg: 400, xl: 500 },
                      mt: { xs: 2, lg: 15, xl: 5 },
                      width: "100%",
                      height: "auto",
                    }}
                    component="img"
                    src={bioDiversity}
                    style={{ filter: "saturate(150%)" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKTypography variant="body3" color="text">
                    The carbon credit marketplace has exploded in the last decade. The barrier to entry - costly soil measurement - excludes the majority of ranchers/farmers from identifying, and
                    selling, their carbon credits. On the demand side, buyers have greater budgets to buy nature-based
                    carbon credits.
                    <br />
                    <br />
                    CarbonAGX is building a community of buyers and sellers. We have created a tool that measures the
                    carbon sequestered based on a rancher/farmer's conservation practices. Producers can see the price per
                    ton/acre of carbon sequestered. The price of a carbon credit in the voluntary market fluctuates based on
                    the quality of the credit and the third-party verifier. A nature-based carbon credit is equal to one ton of
                    carbon dioxide per acre that is not released into the atmosphere.
                    <br />
                    <br />
                    The CarbonAGX Calculator<sup style={{ fontSize: 8 }}>TM</sup> is a tool designed to help producers learn about their conservation practices,
                    evaluate their income potential, and help them decide on which conservation practices to adopt.
                    <br />
                    <br />
                    Learn more <a href="/calculator">CarbonAGX Calculator<sup style={{ fontSize: 8 }}>TM</sup></a>
                    <br />
                    <br />
                    We want to hear from you! <a href="/contact-us">Contact Us</a>
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
