/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
const form = {
  formId: "calculator-form",
  formField: {
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    county: {
      name: "county",
      label: "County",
      type: "text",
      errorMsg: "County is required.",
    },
  },
};

export default form;
