/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// CarbonAGX Website base styles
import colors from "assets/theme/base/colors";

const { text } = colors;

export default {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};
