/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/

import { Typography } from "@mui/material";

export const ACRE_FACTOR = 2500; // multiplier for slider value (0-100)

// #region result data functions
export const categories = [
  "Cropland Management",
  "Grazing Lands",
  "Woody Plantings",
  "Restoration of Disturbed Lands",
];

export const extractPracticeMap = (resultsData) => {
  // create a set of unique practice classes from the result
  const classSet = new Set(resultsData.map((x) => x.class));
  // create a new map w/ class as key and empty set as value
  const practiceMap = new Map();
  classSet.forEach((x) => practiceMap.set(x, new Map()));

  const parseCpsName = (x) => {
    let cpsName = x.cps_name.slice(0, x.cps_name.indexOf("(")).trim();

    const keywordMap = new Map([
      ["Cropland Management", ["No-Till", "Reduced Till", "Crop Rotation", "Combustion System"]],
    ]);

    const keywords = keywordMap.get(x.class);

    if (keywords) {
      [...keywords].forEach((y) => {
        if (cpsName.indexOf(y) >= 0) {
          cpsName = y;
        }
      });
    }

    return cpsName;
  };

  resultsData.forEach((x) => {
    if (x.isMultipleImplementation === false) {
      const cpsName = parseCpsName(x);
      practiceMap.get(x.class).set(cpsName, x.cpsnum);
    }
  });

  return practiceMap;
};
// #region result data functions

export const calculateSoC = (co2InMetricTon) => {
  const retValue = {};
  const socInLbs = (co2InMetricTon * 2000 * 12) / 44;
  const somInLbs = (socInLbs * 100) / 58;
  const numberUSLocale = Intl.NumberFormat("en-US");
  retValue.soc = numberUSLocale.format(Math.round(socInLbs)).concat(" lbs/acre");
  retValue.som = numberUSLocale.format(Math.round(somInLbs)).concat(" lbs/acre");
  return retValue;
};

export const updateDataPoints = (rawData, price, area, factor=ACRE_FACTOR) => {
  const retValue = rawData.map((y) => {
    const x = y;
    const outcomeVale =
      y.total_ghg_co2 > 0 ? (y.total_ghg_co2 * price * area * factor).toFixed(0) : 0;
    const dollarUSLocale = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const avgVString = dollarUSLocale.format(outcomeVale).replace(".00", "");

    let maxV =
      y.total_ghg_co2_max > 0
        ? (y.total_ghg_co2_max * price * area * ACRE_FACTOR).toFixed(0)
        : outcomeVale;
    maxV = maxV < outcomeVale ? outcomeVale : maxV;
    const maxVString = dollarUSLocale.format(maxV).replace(".00", "");

    let minV =
      y.total_ghg_co2_min > 0 ? (y.total_ghg_co2_min * price * area * ACRE_FACTOR).toFixed(0) : 0;
    minV = minV > outcomeVale ? outcomeVale : minV;
    const minVString = dollarUSLocale.format(minV).replace(".00", "");

    x.avg = avgVString;
    x.min = minVString;
    x.max = maxVString;
    x.ghg = y.total_ghg_co2.toString().concat(" ton/acre");
    x.outcomeValue = avgVString;
    [x.implementationDesc] = y.implementation;

    if (!x.soc) {
      const soilOrganicX = calculateSoC(y.soil_carbon_co2);
      x.soc = soilOrganicX.soc;
      x.som = soilOrganicX.som;
    }

    return y;
  });

  return retValue;
};

export const getTopIndices = (rawData, byCategory, take) => {
  const retValue = rawData
    .filter((x) => x.class.normalize() === byCategory && !x.isMultipleImplementation)
    .sort((a, b) => (a.total_ghg_co2 > b.total_ghg_co2 ? -1 : 1))
    .map((y) => y.id)
    .slice(0, take);

  return retValue;
};

export const getTopOutcomesByCategory = (rawData, byCategory, filters, take = 5) => {
  const retValue = rawData
    .filter(
      (x) =>
        x.class.normalize() === byCategory &&
        !x.isMultipleImplementation &&
        (!filters || filters.length === 0 || filters.indexOf(x.cpsnum) > -1)
    )
    .sort((a, b) => (a.total_ghg_co2 > b.total_ghg_co2 ? -1 : 1));

  return retValue.length > take ? retValue.slice(0, take) : retValue;
};

export const getTopOutcomes = (rawData, selectedCategories, filters, take = 5) => {
  const ret = {};
  for (let i = 0; i < selectedCategories.length; i += 1) {
    ret[selectedCategories[i]] = getTopOutcomesByCategory(
      rawData,
      selectedCategories[i],
      filters,
      take
    );
  }
  return ret;
};

// #endregion
