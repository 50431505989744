/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// @mui material components
import Grid from "@mui/material/Grid";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// CarbonAGX Website examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/regen_ag_2.jpg";
import { useState, useEffect } from "react";

// Import profanity filter
import Filter from 'bad-words';

/*const popup = () => {
  <Backdrop>
    <Typography variant="h1">
      Thank you!.
    </Typography>
    <Typography variant="p">
    Your submission has been received. We will be in touch shortly
    </Typography>
  </Backdrop>
};*/

function ContactUs() {
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setemailErrorMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [info, setInfo] = useState("");
  const [infoErrorMessage, setInfoErrorMessage] = useState("");
  const [isInfoValid, setIsInfoValid] = useState(false);
  const [isWholeFormValid, setIsWholeFormValid] = useState(false);
  const [popup, setPopup] = useState(<></>);

  // errors only pop up after fields have been clicked at least once
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [infoFocused, setInfoFocused] = useState(false);
  useEffect(() => {
    document.title = "Contact Us";
  }, []);
  useEffect(() => {
    if (name.replace(/\s/g, '') === "" || email.replace(/\s/g, '') === "" || info.replace(/\s/g, '') === ""
      || !isNameValid || !isEmailValid || !isInfoValid || info.length < 250
      || isCussWord(info) === true) {
      setIsWholeFormValid(false);

      /*if(email.replace(/\s/g, '') === ""){
        setHelperMessage([helperMessage[0], "Please fill out all fields", helperMessage[2]]);
      }else{
        setHelperMessage([helperMessage[0], "", helperMessage[2]]);
      }

      if(email.replace(/\s/g, '') === ""){
        setHelperMessage([helperMessage[0], helperMessage[1], "Please fill out all fields"]);
      }else{
        setHelperMessage([helperMessage[0], helperMessage[1], ""]);
      }*/
    } else {
      setIsWholeFormValid(true);
    }
  }, [info]);

  // username error
  useEffect(() => {
    if(name.replace(/\s/g, '') === ""){
      setNameErrorMessage("Please fill out all fields");
    }else if(!validateName(name)){
      setNameErrorMessage("Please enter a valid name");
    }else{
      setNameErrorMessage("");
    }
  }, [name]);
  // email error
  useEffect(() => {
    if(email.replace(/\s/g, '') === ""){
      setemailErrorMessage("Please fill out all fields");
    }else if(!validateEmail(email)){
      setemailErrorMessage("Please enter a valid email");
    }else{
      setemailErrorMessage("");
    }
  }, [email]);
  // info error
  useEffect(() => {
    if(info.length < 150){
      setInfoErrorMessage("Please enter at least 150 characters");
    }else if(!isInfoValid){
      setInfoErrorMessage("Profanity is not allowed in this form.");
    }else{
      setInfoErrorMessage("");
    }
  }, [info]);

  const validateName = (name) => {
    const regex = /^[a-zA-Z ]{2,30}$/;
    return regex.test(name);
  }

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const isCussWord = (text) => {
    let cussList = ['fuck','shit','bitch']
    if(cussList.some(cussWord => text.toLowerCase().includes(cussWord))){
      return true;
    }else{
      return false;
    }
  }

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox bgColor="primary" coloredShadow="info" borderRadius="lg" p={2} mx={2} mt={-3}>
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      error={!isNameValid}
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      helperText={nameErrorMessage}
                      fullWidth
                      onChange={(e) => {
                        setName(e.target.value, () => { });
                        console.log("info: " + name + ", " + email + ", " + info);
                        setIsNameValid(validateName(e.target.value), () => { });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      error={!isEmailValid}
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      helperText={emailErrorMessage}
                      fullWidth
                      onChange={(e) => {
                        setEmail(e.target.value, () => { });
                        //setInfoValid([infoValid[0], validateEmail(e.target.value)], () => { });
                        setIsEmailValid(validateEmail(e.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      error={!isInfoValid}
                      variant="standard"
                      label="How can we help you?"
                      placeholder="Describe your problem in at least 150 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      helperText={infoErrorMessage}
                      onChange={(e) => {
                        setInfo(e.target.value, () => { });
                        setIsInfoValid(!isCussWord(e.target.value), () => { });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton variant="gradient" color="info" onClick={
                    () => {
                      if (!isWholeFormValid) {
                        alert("Please correct the errors underneath each section before submitting!")
                      } else {
                        // code to activate backdrop
                        filter.clean(info);
                        alert ("info 2; " + info);
                        alert("Thank you! Your submission has been received. We will be in touch shortly");
                        // send data to backend
                        window.location.reload(false);
                      }
                    }
                  }>
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
