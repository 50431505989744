/*
=========================================================
* CarbonAGX Website
=========================================================
* 
* Copyright 2022 CarbonAGX
=========================================================
* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// CarbonAGX Website examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/money-trees.jpg";
import CalcWidget from "./CalcWidget";
import { Typography } from "@mui/material";
import { useEffect } from "react";
function Calculator() {
  useEffect(() => {
    document.title = "CarbonAGX Calculator";  
  }, []);
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        width="100%"
        height="auto"
        sx={{
          minHeight: { xs: "10vh", sm: "40vh" },
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.3),
              rgba(gradients.dark.state, 0.3)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} alignContent="center">
              <MKTypography inline align="center" variant="h3" color="white" mt={16} mb={13}>
                CarbonAGX Calculator<sup style={{ fontSize: 10 }}>TM</sup>
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          pb: 10,
          mx: { xs: 0, sm: 2, lg: 3 },
          mt: { xs: -10, md: -8 },
          mb: 4,
          backgroundColor: ({ palette: { light }, functions: { rgba } }) => rgba(light.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" sx={{ pt: { xs: 4, sm: 14 } }}>
          <Container>
            <CalcWidget />
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Calculator;
