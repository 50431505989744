/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
