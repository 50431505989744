/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "../components/DefaultCell";

const splitter = (origString, l) => {
  let str = origString;
  const strs = [];
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(" ");
    pos = pos <= 0 ? l : pos;
    strs.push(str.substring(0, pos));
    let i = str.indexOf(" ", pos) + 1;
    if (i < pos || i > pos + l) i = pos;
    str = str.substring(i);
  }
  strs.push(str);
  return (
    <span>
      {strs.map((x) => (
        <span>
          {x}
          <br />
        </span>
      ))}
    </span>
  );
};

const formatImplementationColumn = (rawData) =>
  rawData.length > 1 ? (
    <ul>
      {rawData.map((x) => (
        <li>{splitter(x, 60)}</li>
      ))}
    </ul>
  ) : (
    splitter(rawData[0], 60)
  );

const dataTableData = {
  columns: [
    {
      Header: "implementation",
      accessor: "implementation",
      width: "40%",
      Cell: ({ value }) => formatImplementationColumn(value),
    },
    {
      Header: "avg carbon credit",
      accessor: "avg",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "max carbon credit",
      accessor: "max",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "min carbon credit",
      accessor: "min",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "soil organic carbon gain",
      accessor: "soc",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "soil organic matter gain",
      accessor: "som",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],

  rows: [],
};

export default dataTableData;
