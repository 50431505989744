/*
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/
// @mui material components
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Features page components
import RegenAg from "pages/Resources/components/RegenAg";
import CarbonCredits from "pages/Resources/components/CarbonCredit";
import NewsLetter from "pages/Resources/components/NewsletterOne";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { useEffect } from "react";

function Features() {
  useEffect(() => {
    document.title = "Resources";  
  }, []);
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" pt={14}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <RegenAg sectionId="regen-ag" />
                <CarbonCredits sectionId="carbon-credits" />
                <NewsLetter sectionId="news-letter" />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Features;
