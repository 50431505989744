/* eslint-disable no-param-reassign */
/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// CarbonAGX Website components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function NewsletterOne({ sectionId, header, description }) {
  return (
    <MKBox component="section" py={40} id={sectionId}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} mr="auto">
            <MKTypography variant="h4" mb={1}>
              {header}
            </MKTypography>
            <MKTypography variant="body4" color="text">
              {description}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column" justifyContent="center" ml="auto">
            <MKBox component="form" method="" action="">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <MKInput label="Your Email..." fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKButton variant="gradient" color="info" fullWidth sx={{ height: "100%" }}>
                    Subscribe
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
// Setting default props for the SimpleInfoCard
NewsletterOne.defaultProps = {
  sectionId: "news-letter",
  header: "Get News and Insights!",
  description: "Join our newsletter and get news in your inbox!",
};

// Typechecking props for the SimpleInfoCard
NewsletterOne.propTypes = {
  sectionId: PropTypes.node,
  header: PropTypes.string,
  description: PropTypes.string,
};

export default NewsletterOne;
