/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
/* eslint react/prop-types: 0 */
import { Button, CardActions, Box, Typography, Card } from "@mui/material";

// Billing page components
import TopOutcomeRow from "../TopOutcomeRow";

function TopOutcomesCard({ practiceCategory, outcomeData, showSh, showCommand, lessWidget }) {
  return !outcomeData || outcomeData.length === 0 ? null : (
    <Card
      sx={{
        height: "100%",
        sm: { maxWidth: 445 },
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
      }}
    >
      <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight="medium">
          Top {practiceCategory} Practices
        </Typography>
      </Box>
      <Box p={2}>
        <Box component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {outcomeData.map((row) => (
            <TopOutcomeRow
              implementation={row.implementationDesc}
              implementationDetail={row.implementationDesc}
              outcomeValue={showSh ? row.som : row.outcomeValue}
            />
          ))}
        </Box>
      </Box>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            showCommand(lessWidget);
          }}
        >
          Go back
        </Button>
      </CardActions>
    </Card>
  );
}

export default TopOutcomesCard;
