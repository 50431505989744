/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
// prop-types is a library for typechecking of props
/* eslint react/prop-types: 0 */

import Typography from "@mui/material/Typography";

function DefaultCell({ value, suffix }) {
  return (
    <Typography variant="caption" fontWeight="medium" color="text">
      {value}
      {suffix && (
        <Typography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </Typography>
      )}
    </Typography>
  );
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

export default DefaultCell;
