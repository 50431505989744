/**
=========================================================
* CarbonAGX Website - v2.0.0
=========================================================

* 
* Copyright 2022 CarbonAGX



 =========================================================

* 
*/

// CarbonAGX Website base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const { transparent } = colors;
const { lg } = boxShadows;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      borderRadius: borderRadius.md,
    },
  },
};
