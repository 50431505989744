/**
=========================================================
* ACCES Calculator - v0.1.0
=========================================================

* Product Page: https://www.mjc25.com/acces
* Copyright 2022 MjC25 LLC (https://www.mjc25.com)

Coded by Kevin Sun

=========================================================
*/
// prop-types is a library for typechecking of props
/* eslint react/prop-types: 0 */

// @mui material components

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function TopOutcomeRow({ implementation, implementationDetail, outcomeValue }) {
  return (
    <Box
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
    >
      <Box lineHeight={1.125} mr={1} alignItems="left">
        <Tooltip title={implementationDetail} placement="right">
          <Typography variant="button" fontWeight="regular" color="text">
            {implementation}
          </Typography>
        </Tooltip>
      </Box>
      <Box alignItems="right" ml={1}>
        <Typography display="block" variant="button" fontWeight="medium" color="success" noWrap>
          {outcomeValue}
        </Typography>
      </Box>
    </Box>
  );
}

export default TopOutcomeRow;
